<template>
  <div :id="id"></div>
</template>
<script>
import Typed from 'typed.js';
export default {
  name: "typed",
  props: {
    id: {
      type: String,
      default: ()=> "",
    },
    strings: {
      type: String,
      default: ()=> "",
    },
  },
  data(){
    return {
      typed: null,
    };
  },
  mounted() {
    const options = {
      strings: [this.strings],
      typeSpeed: 50,      // 打印速度
      startDelay: 300,    // 开始之前的延迟300毫秒
      loop: false,        // 是否循环
    };
    this.typed = new Typed(`#${this.id}`, options);
  },
  methods: {
     
  },
}
</script>
<style lang="scss" scoped>

</style>

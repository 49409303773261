<template>
  <div class="sideBar" v-if="showSide">
    <div
      @mouseover="showPhone = true"
      @mouseleave="showPhone = false"
    >
      <i class="el-icon-service"></i>
      <!-- <img src="../assets/image/kefu.png"> -->
      <p>电 话</p>
      <div class="phone" v-show="showPhone">025-58113035</div>
    </div>
    <div class="backTop" @click="toTop">
      <img src="../assets/image/top.png">
      <!-- <i class="el-icon-top"></i> -->
      <p>顶 部</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "totop",
  data(){
    return {
      showSide: true,
      showPhone: false,
    };
  },
  mounted() {
    // window.addEventListener('scroll', this.scrollToTop)
  },
  methods: {
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    // scrollToTop() {
    //   let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    //   if (scrollTop > 100) {
    //     this.showSide = true;
    //   } else {
    //     this.showSide = false;
    //   }
    // }
  },
  destroyed(){
    // window.removeEventListener('scroll', this.scrollToTop); 
  }
}
</script>
<style lang="scss" scoped>
.sideBar{
	position: fixed;
	width: 50px;
	bottom: 20%;
	right: 1px;
	z-index: 99999;
	background: #006DFB;
	padding: 10px 0;
	color: #FFFFFF;
	font-size: 12px;
	cursor: pointer;
}
.sideBar div{
	 text-align: center;
}
.sideBar img{
	width: 20px;
	// height: 20px;
}
.sideBar i{
  font-size: 22px;
}
.sideBar p{
	margin-top: 5px;
}
.sideBar .backTop{
	margin-top: 20px;
}
.phone{
	position: absolute;
	right: 50px;
	top: 0;
	width: 160px;
	height: 66px;
	line-height: 66px;
	background-color: #006DFB;
	text-align: center;
	font-size: 16px;
	font-weight: bold;
  font-style: italic;
  letter-spacing: 2px;
}
</style>

<template>
  <div id="layout">
    <el-container>
      <div style="width: 100%;height:100%;">
        <layoutHeader></layoutHeader>
        <el-container>
          <router-view></router-view>
        </el-container>
        <layoutFooter></layoutFooter>
      </div>
    </el-container>
  </div>
</template>
<script>
import layoutHeader from "./components/header";
import layoutFooter from "./components/footer";
export default {
  name: "layout",
  components: {
    layoutHeader,
    layoutFooter
  },
};
</script>
<style>
.main-enter,
.main-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.main-enter-active {
  transition: all 0.2s;
}
.main-leave-active {
  position: absolute;
  transition: all 0.3s;
}
</style>
<style lang="scss">
* {
  margin: 0px;
  padding: 0px;
}
body {
  background-color: #f2f2f2;
  font-size: 14px;
  color: #333333;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}
$top: top;
$bottom: bottom;
$left: left;
$right: right;
$leftright: ($left, $right);

%w100 {
  width: 100%;
}

%h100 {
  height: 100%;
}

%cursor {
  cursor: pointer;
}

html,
body,
#layout,
.el-container,
#asideNav,
ul.el-menu {
  @extend %h100;
}
@mixin set-value($side, $value) {
  @each $prop in $leftright {
    #{$side}-#{$prop}: $value;
  }
}
</style>

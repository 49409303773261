import Vue from "vue";
import Vuex from "vuex";

import { defaultRouter } from "@/router/index";
import { sesStorage } from "../utils/storage";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    userName:"",
    isCollapse: false,
    logoShow: false,
    routers: defaultRouter,
  },
  mutations: {
    collapse(state) {
      state.isCollapse = !state.isCollapse;
      if (state.logoShow) {
        setTimeout(function () {
          state.logoShow = false;
        }, 300);
      } else {
        state.logoShow = true;
      }
    },
    // setRouters(state, routers){
    //   state.addRouters = routers // 保存动态路由用来addRouter
    //   state.routers = defaultRouter.concat(routers) // 所有有权限的路由表，用来生成菜单列表
    //   console.log(routers)
    // }
  },
  actions: {
    collapse({ commit }, arg) {
      commit("collapse", arg);
    },
  },
  getters: {
    userName(state) {
      if (!state.userName) {
        state.userName = sesStorage.get("name");
      }
      return state.userName;
    },
    routers: (state) => state.routers,
    logoShow: (state) => state.logoShow,
    isCollapse: (state) => state.isCollapse,
  },
  modules: {},
});

export default store;

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/css/style.scss";
Vue.use(ElementUI);

import tools from "../public/lib/tools.js";
Vue.prototype.$tools = tools;

import typedMent from "@/components/typedMent.vue";
Vue.component("typedMent", typedMent);     //全局自定义打印效果组件

import BaiduMap from 'vue-baidu-map'
Vue.use( BaiduMap, { ak : "xLpNatv5PN7tLbIb5zBMSjb2fIIFaIQu" } )

import qs from "qs";
Vue.prototype.$qs = qs;

import moment from "moment"
Vue.prototype.$moment = moment;

//路由跳转后默认回到顶部
router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
  next()
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");


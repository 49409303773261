//单个方法引入，使用时在页面需引入所需要的方法，调用 方法名.
// export const checkNumber= (data)=>{
// if (/\D/.test(data)) {
//     return false;
//   } else {
//     return true;
//   }
// }

//定义全局方法，只需在main.js引入，调用 this.$tools.方法名
// 数字验证，true表示为数字 
const checkNumber = (data) => {
  if (/\D/.test(data)) {
    return false;
  } else {
    return true;
  }
}
//空值校验
const checkEmpty=(data)=>{
  if(data==""){
    return false;
  }else{
    return true;
  }
}
//中文
const checkCn=(data)=>{
  if(/^[\u4E00-\u9FA5]+$/.test(data)){
    return false;
  }else{
    return true;
  }
}
//英文
const checkEn=(data)=>{
  if(/^[a-zA-Z]+$/.test(data)){
    return false;
  }else{
    return true;
  }
}



export default {
  checkNumber,checkEmpty,checkCn,checkEn
}
<template>
  <div class="footer">
    <div class="yj-frame-footer">
      <div class="yj-copyright">
        <div class="footLogo">
          <div class="foot-logo"></div>
          <div class="ewm-sect">
            <div style="margin-right: 50px;"><div class="yjkj-ewm">禹江科技</div></div>
            <div><div class="yjt-ewm">一江通</div></div>
          </div>
        </div>
        <div class="alink">
          <span>友情链接：</span>
          <a href="https://www.mot.gov.cn/" target="_blank">中华人民共和国交通运输部</a>
          <a href="https://cjhy.mot.gov.cn/" target="_blank">交通运输部长江航务管理局</a>
          <a href="http://www.cjhdj.com.cn/" target="_blank">长江航道局</a>
          <a href="https://cj.msa.gov.cn/" target="_blank">中华人民共和国长江海事局</a>
          <a href="https://sxth.mot.gov.cn/" target="_blank">长江三峡通航管理局</a>
          <a href="http://www.cjw.gov.cn/" target="_blank">水利部长江水利委员会</a>
          <a href="http://jtyst.jiangsu.gov.cn/" target="_blank">江苏省交通运输厅</a>
          <a href="https://jtt.hubei.gov.cn/" target="_blank">湖北省交通运输厅</a>
          <a href="https://jtt.ah.gov.cn/" target="_blank">安徽省交通运输厅</a>
          <a href="http://jt.jiangxi.gov.cn/" target="_blank">江西省交通运输厅</a>
          <a href="http://jtt.hunan.gov.cn/" target="_blank">湖南省交通运输厅</a>
          <a href="http://jtyst.zj.gov.cn/" target="_blank">浙江省交通运输厅</a>
          <a href="http://jtt.sc.gov.cn/" target="_blank">四川省交通运输厅</a>
          <a href="https://jtj.cq.gov.cn/" target="_blank">重庆市交通局</a>
        </div>
        <el-divider></el-divider>
        <div class="copyright">
          Copyright &copy; 2015-{{currentYear}} &nbsp; <span> | 江苏禹江科技有限公司 版权所有 | </span>
          <a href="http://beian.miit.gov.cn" target="_blank">
            苏ICP备15049923号-2
          </a> 
          <span> | </span>
          <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32128202000111" target="_blank">
            苏公网安备 32128202000111号
          </a>
      </div>
	  </div>
  </div>
  <to-top></to-top>
</div>
</template>
<script>
import toTop from '@/components/toTop.vue'
export default {
  name: "Footer",
  components: {
    toTop
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  },
  data(){
    return {
        
    };
  },
  mounted() {

  },
}
</script>
<style lang="scss" scoped>
.footer{
  height: 200px;
}
.bot-code{
	display: flex;
	margin: 50px auto;
	width: 1200px;
}
.footLogo{
	width: 1200px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.bot-code .wx-code{
	height: 100px;
	margin: 0 auto;
	box-sizing: inherit;
	width: 400px;
}
.bot-code .wx-code:nth-child(1){
	background-position: 0% 0;
}
.bot-code .wx-code:nth-child(3){
	background-position: 100% 0;
}
.ewm-sect .yjkj-ewm{
  background:url("~@/assets/image/ermaweima_1.png");
  background-size: 100%;
  background-repeat: no-repeat;
}
.ewm-sect .yjt-ewm{
  background:url("~@/assets/image/ermaweima_3.png");
  background-size: 100%;
  background-repeat: no-repeat;
}
.yj-copyright {
	background-color: #044594;
	color: #fff;
	text-align: center;
	padding: 10px 0;
  padding-bottom: 10px;
}
.foot-logo {
	/*margin: 0 auto;*/
	width: 192px;
	height: 56px;
	background: url("~@/assets/image/logo2.png") no-repeat center center;
  background-size: 100%;
}
.copyright {
	line-height: 3;
	color: #FFFFFF;
	font-size: 13px;
  a{
    color: #FFFFFF;
    letter-spacing: 1px;
  }
  span{
    letter-spacing: 1px;
  }
}
.ewm-sect >div{
	float: left;
	width: 100px;
  margin-top: 20px;
}
.ewm-sect >div >div{
	text-align: center;
	padding-top: 115px;
	font-size: 14px;
	background-repeat: no-repeat;
	background-position: 14px 0;
	color: #FFFFFF;
}
.alink{
  width: 1200px;
  margin: 30px auto;
  margin-bottom: 20px;
  span{
    color: #FFFFFF;
  }
  a{
    color: #FFFFFF;
    margin: 10px;
    line-height: 30px;
    text-decoration: none!important;
    letter-spacing: 1px;
  }
}
.el-divider{
  background-color: #2F64A5;
  margin: 15px 0;
}
</style>

<template>
  <div id="app">
    <router-view> </router-view>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      
    };
  },
  components: {
    
  },
  methods: {
    
  },
};
</script>
<style lang="scss" scoped>
#app {
  font-family: "Helvetica Neue,Helvetica,Arial,Hiragino Sans GB,Hiragino Sans GB W3,Microsoft YaHei UI,Microsoft YaHei,WenQuanYi Micro Hei,sans-serif";
  // font-family: "黑体";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
  min-width: 1200px;
  font-size: 14px;
}
</style>

<template>
  <div class="head">
    <el-header class="header">
      <el-image class="headLogo" :src="url" @click="ToIndex()"></el-image>
      <el-menu  mode="horizontal" :default-active="activeIndex">
        <el-submenu index="1">
          <template slot="title">产品及解决方案</template>
          <div class="leftOne">
            <div>产品</div>
            <el-menu-item index="1" @click="RouterGO('/yjtsy','1')">一江通水运智慧物流服务平台</el-menu-item>
            <el-menu-item index="1" @click="RouterGO('/bdwg','1')">北斗5G智能数据采集仪</el-menu-item>
            <el-menu-item index="1" @click="RouterGO('/ais','1')">海事北斗AIS双向收发5G智能一体机</el-menu-item>
          </div>
          <div class="rightTwo">
            <div>解决方案</div>
            <el-menu-item index="1" @click="RouterGO('/zhmt','1')">智慧码头</el-menu-item>
            <el-menu-item index="1" @click="RouterGO('/sysg','1')">水运施工数字化管理系统</el-menu-item>
            <el-menu-item index="1" @click="RouterGO('/bczn','1')">趸船智能管理系统</el-menu-item>
            <el-menu-item index="1" @click="RouterGO('/cbsz','1')">船舶数字化经营管理系统</el-menu-item>
            <el-menu-item index="1" @click="RouterGO('/zhsy','1')">智慧水运其他定制化方案</el-menu-item>
          </div>
        </el-submenu>
        <el-menu-item index="2" @click="RouterGO('/industryNews','2')">新闻资讯</el-menu-item>
        <el-submenu index="3">
          <template slot="title">服务支持</template>
          <div class="leftOne">
            <el-menu-item index="3" @click="RouterGO('/download','3')">下载中心</el-menu-item>
          </div>
          <div class="rightTwo">
            <el-menu-item index="3" @click="RouterGO('/aftersale','3')">售后保障</el-menu-item>
          </div>
        </el-submenu>
        <el-submenu index="4">
          <template slot="title">关于禹江</template>
          <div class="leftOne">
            <el-menu-item index="4" @click="RouterGO('/introduce','4')">公司介绍</el-menu-item>
          </div>
          <div class="rightTwo">
            <el-menu-item index="4" @click="RouterGO('/joinus','4')">加入我们</el-menu-item>
          </div>
        </el-submenu>
        <div class="yjt" @click="toYJT"> 一江通PC端</div>
      </el-menu>  
    </el-header>
  </div>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      url: require("@/assets/image/logo.png"),
      activeIndex: this.$route.query.activeIndex?this.$route.query.activeIndex:"",
    }
  },
  // 通过监听器解决  导航菜单在页面刷新时会回到默认项
  watch: { 
    '$route' (to, from) {
    // 监听路由的变化  如果路由发生改变则当前tab栏默认值也相应改变
        this.activeIndex = to.query.activeIndex;
    }
  },
  mounted() {
   
  },
  methods: {
    RouterGO(url,activeIndex) {
      this.$router.push({
        path: url,
        query: {activeIndex:activeIndex}
      })
    },
    ToIndex(){
      window.location.href = '/'
    },
    toYJT(){
      window.open("http://yjzhwh.com", '_blank');
    },
  }
}
</script>
<style lang="scss" scoped>
.head {
  width: 100%;
  background: #fff;
}
.header {
  width: 1200px!important;
  height: 80px!important;
  line-height: 80px;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0;
}
.aboutyjt{
  font-family: "黑体";
  font-weight: bold;
}
.title {
  font-size: 28px;
  font-weight: bold;
}
.headLogo{
  // width: 168px;
  // height: 38px;
  overflow: inherit!important;
  cursor: pointer;
  /deep/ .el-image__inner{
    vertical-align: middle;
    height: auto;
  }
}
.el-menu--horizontal>.el-menu-item{
  height: 4.9rem;
  line-height:  4.9rem;
  color: #333333;
  // font-weight: bold;
  letter-spacing: 1.5px;
}
/deep/ .el-menu--horizontal>.el-submenu .el-submenu__title{
  height: 4.9rem;
  line-height:  4.9rem;
  color: #333333;
  // font-weight: bold;
  letter-spacing: 1.5px;
}
/deep/ .el-menu--horizontal>.el-submenu .el-submenu__icon-arrow{
  display: none;
}
.el-menu--horizontal .el-menu .el-menu-item{
  // padding: 0 3rem;
  font-size: 15px;
  letter-spacing: 1.5px;
}
/deep/.el-submenu__title, .el-menu-item{
  padding: 0 ;
  font-size: 15px;
}
.el-menu--horizontal .el-menu .el-menu-item a{
  color: #333333;
}
.el-menu--horizontal>.el-menu-item.is-active{
  border-bottom: 3px solid #115FEF;
  color: #333333;
  // font-weight: bold;
  letter-spacing: 1.5px;
}
.el-menu::after, .el-menu::before{
  display: none;
}
/deep/ .el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
  border-bottom: 3px solid #115FEF;
  color: #333333;
  // font-weight: bold;
}
.phone{
  margin-right: 30px;
  position: absolute;
  right: 0;
  top: 0;
}
.phone span{
  font-size: 16px;
  color: #2D6CEE;
  font-style: italic;
  font-weight:bold;
  letter-spacing: 0.6px;
}
.el-menu.el-menu--horizontal{
  display: flex;
  justify-content: space-between;
  width: 680px;
  margin-right: 150px;
}
.leftOne{
  margin-right: 10rem;
}
.leftOne li, .leftOne div, .rightTwo li, .rightTwo div{
  height: 3rem!important;
  line-height: 3rem!important;
  padding: 0 0rem!important;
  // text-align: center!important;
  color: #333333!important;
}
.leftOne>div, .rightTwo>div {
  border-bottom: 1px solid #cbcbcb;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 2px;
}
.yjt{
  cursor: pointer;
}
</style>

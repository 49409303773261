import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/views/layout/index";

const Index = () => import("@/views/index/index");
const NotFound = () => import("@/views/page404");

/*重写路由的push方法*/
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};
Vue.use(VueRouter);

let defaultRouter = [
  {
    path: "/",
    component: Layout,
    alone: true,
    children: [
      {
        path: "/",
        name: "首页",
        component: Index,
      },
    ],
  },
  {
    path: "/product",
    component: Layout,
    name: "产品及解决方案",
    alone: true,
    children: [
      {
        path: "/yjtsy",
        name: "一江通水运智慧物流服务平台",
        component: () => import('../views/product/yjtsy.vue'),
      },
      {
        path: "/bdwg",
        name: "北斗5G智能数据采集仪",
        component: () => import('../views/product/bdwg.vue'),
      },
      {
        path: "/ais",
        name: "海事北斗AIS双向收发5G智能一体机",
        component: () => import('../views/product/ais.vue'),
      },
      {
        path: "/zhmt",
        name: "智慧码头",
        component: () => import('../views/product/zhmt.vue'),
      },
      {
        path: "/sysg",
        name: "水运施工数字化管理系统",
        component: () => import('../views/product/sysg.vue'),
      },
      {
        path: "/bczn",
        name: "趸船智能管理系统",
        component: () => import('../views/product/bczn.vue'),
      },
      {
        path: "/cbsz",
        name: "船舶数字化经营管理系统",
        component: () => import('../views/product/cbsz.vue'),
      },
      {
        path: "/zhsy",
        name: "智慧水运其他定制化方案",
        component: () => import('../views/product/zhsy.vue'),
      },
      
      // {
      //   path: "/yjzh",
      //   name: "禹江智盒",
      //   component: () => import('../views/product/yjzh.vue'),
      // },
     
      // {
      //   path: "/zhhy",
      //   name: "禹江航运",
      //   component: () => import('../views/product/zhhy.vue'),
      // },
      // {
      //   path: "/zhgd",
      //   name: "禹江工地",
      //   component: () => import('../views/product/zhgd.vue'),
      // },
    ],
  },
  {
    path: "/serviceSupport",
    component: Layout,
    name: "服务支持",
    alone: true,
    children: [
      {
        path: "/download",
        name: "下载中心",
        component: () => import('../views/serviceSupport/download.vue'),
      },
      {
        path: "/aftersale",
        name: "售后保障",
        component: () => import('../views/serviceSupport/aftersale.vue'),
      },
    ],
  },
  {
    path: "/aboutUs",
    component: Layout,
    name: "关于禹江",
    alone: true,
    children: [
      {
        path: "/introduce",
        name: "公司介绍",
        component: () => import('../views/aboutUs/introduce.vue'),
      },
      {
        path: "/joinus",
        name: "加入我们",
        component: () => import('../views/aboutUs/joinus.vue'),
      },
    ],
  },
  {
    path: "/industryNews",
    component: Layout,
    alone: true,
    children: [
      {
        path: "/industryNews",
        name: "新闻资讯",
        component: () => import('../views/industryNews/index.vue'),
      },
      {
        path: "/detail",
        name: "资讯详情",
        component: () => import('../views/industryNews/detail.vue'),
      },
    ],
  },
  {
    path: "/404",
    component: NotFound,
    name: "404",
    hidden: true,
    children: [],
  },
];

// 导出路由
export default new VueRouter({
  routes: defaultRouter,
});
export { defaultRouter };
